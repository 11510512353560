.container {
    display: flex;
    margin: 0 10%;
    justify-content: center;
    align-items: center;
}

.form_container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: space-around;
    align-items: center;
}

.form {
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.row_container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin: 2rem 0;
}

.content_container {
    width: 100%;
}

.input {
    width: 100%;
    height: 3rem;
    border: 1px solid black;
    padding-left: 1rem;
    border-radius: 4px;
}

.buttom_container {
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.button {
    width: 100%;
    height: 3rem;
    background-color: white;
    border: 1px solid black;
    color: black;
    cursor: pointer;
    border-radius: 4px;
}

.button:disabled {
    background-color: lightgray;
    color: gray;
    border-color: gray;
    cursor: not-allowed;
}

.buttonYes {
    width: 100%;
    height: 3rem;
    background-color: white;
    color: green;
    border: 1px solid green;
    cursor: pointer;
    border-radius: 4px;
}

.buttonNo {
    width: 100%;
    height: 3rem;
    background-color: white;
    color: red;
    border: 1px solid red;
    cursor: pointer;
    border-radius: 4px;
}

.trashButton {
    background-color: transparent;
    border: none;
}

.submit_button {
    width: 50%;
    height: 3rem;
    background-color: black;
    color: white;
    cursor: pointer;
    text-decoration: none;
    align-content: center;
    border-radius: 4px;
}

.image_container {
    width: 50%;
}