@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&display=swap');

/* <uniquifier>: Use a unique and descriptive class name
<weight>: Use a value from 100 to 900
<width>: Use a value from 75 to 125 

.truculenta {
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
  font-variation-settings:
  "wdth" <width>;
}
*/

body {
  font-family: "EB Garamond", serif;
}

.App {
  text-align: center;
  margin: auto;
  max-width: 1200px;
}

.home-container {
  background-image: url('./assets/svg\ per\ sito.svg');
  background-color: white;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home-title {
  font-size: 100px;
  font-weight: 700;
  color: white;
}

.home-subtitle-date {
  font-size: 30px;
  font-weight: 300;
  color: black;
}

.home-subtitle-names {
  font-size: 50px;
  font-weight: 500;
  font-style: italic;
  color: black;
}

@media screen and (max-width: 600px) {

  /*   .home-container {
    background-image: url('./assets/canoa_vulcano.JPG');
  }
 */
  .home-title {
    font-size: 70px;
    font-weight: 700;
    color: white;
  }

}