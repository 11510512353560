.container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.title_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 25px 0px;
}

.section_container {
    display: flex;
    flex-direction: column;
}

.home_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.title {
    font-size: 25px;
    margin: 10px 0px;
}

.sub_title {
    font-size: 25px;
    margin: 10px 0px;
    text-align: start;
}

.map_container {
    height: 70vh;
    width: 80%
}

@media screen and (max-width: 600px) {
    .map_container {
        width: 100%;
    }

    .home_container {
        flex-direction: column;
    }
    
}