.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 10% 40px 10%;
}

.text {
    font-size: 25px;
    margin: 10px 0;
}

.image {
    width: 50%;
    margin: 40px 10% 0 10%;
}

@media screen and (max-width: 600px) {
    .image {
        width: 100%;
    }
}