.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px 10%;
}

.text {
    font-size: 25px;
    margin: 10px 0;
}

.little_text {
    font-size: 25px;
    margin: 10px 0;
}

@media screen and (max-width: 600px) {
    .little_text {
        font-size: 20px;
    }
}